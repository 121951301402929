.contact-form-container {
  padding-left: 10vw;
  padding-top: 5vh;

}

.contact-form-container h2 {
  color: #000000;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
}

.contact-info {
  padding: 5px;
  margin-top: 10px;
  font-size: 1.1em;
}

.contact-info p {
  margin-bottom: 15px;
  color: #4e4e4e;
}

.contact-info i {
  color: #4294e3; /* Primary color */
  margin-right: 5px;
}

.social-links {
  margin-top: 20px;
}

.social-links p {
  margin-bottom: 10px;
}

.social-links a {
  color: #000000;
  margin-right: 25px;
  font-size: 1.5em;
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.contact-form-container input,
.contact-form-container textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

.contact-form-container textarea {
  resize: vertical;
  height: 120px;
}

.contact-form-container button {
  background-color: #8f12fd; /* Secondary color */
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form-container button:hover {
  background-color: #7a0ce6;
}

.success-message {
  color: #2ecc71;
  margin-top: 10px;
}

.error-message {
  color: #e74c3c;
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-form-container {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
  }

  .contact-form-container h2 {
    grid-column: 1; /* Reset to single column */
  }
}
